
@tailwind base;
@tailwind components;
@tailwind utilities;
.bg{
  background-color: #FBF9F7;
}
button{
  background-color: #4ABAB9;
}

