.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input{
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}
input:focus {
  border-color: #66afe9;
}

textarea {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  resize: vertical;
  transition: border-color 0.2s ease-in-out;
}

/* Focus styles for textarea */
textarea:focus {
  border-color: #66afe9;
}

/* Styles for select */
select {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  background-color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: border-color 0.2s ease-in-out;
}

/* Focus styles for select */
select:focus {
  border-color: #66afe9;
}

/* Base label styles */
label {
  display: inline-block;
  font-size: 16px;
  color: #333;
  position: relative;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  font-size: small;
}

/* Animation effect on hover */
label:hover {
  color: #007bff;
}

/* Animation effect when label is clicked */
label:active {
  color: #ff4500;
}

/* Animation effect when label is focused */
label:focus-within {
  color: #00cc99;
}

/* Style for input elements with the 'required' attribute */
label.required::after {
  content: '*';
  color: red;
  position: absolute;
  top: 0;
  right: -10px;
}

/* Add a tooltip when label is focused */
label:focus-within::before {
  content: attr(title);
  color: #333;
  background-color: #f1f1f1;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  position: absolute;
  top: -25px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

/* Show the tooltip when label is focused */
label:focus-within::before {
  opacity: 1;
  transform: translateY(0);
}

